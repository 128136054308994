<template>
  <p>ForgotUsername</p>
</template>

<script>
export default {
  name: 'ForgotUsername',
};
</script>

<style scoped lang="scss"></style>
